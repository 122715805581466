<template>
	<span
		class="uppercase cursor-pointer font-bold hover:text-primary"
		@click="clicked">
		<span><IconsFontAwesome :icon="icon" /></span>
		<span>&nbsp;{{ text }}</span>
	</span>
</template>

<script setup>
	const { text } = defineProps({
		icon: String,
		text: String,
	})

	const emits = defineEmits(["clicked"])

	const clicked = () => emits("clicked")
</script>

<style lang="scss"></style>
