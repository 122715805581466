<template>
	<div>
		<ModalBase
			container-class="rounded-lg text-primary bg-black border-2 border-primary p-4 sm:p-8 w-full md:w-5/6"
			:clickOutsideEnabled="false"
			ref="seatSelectorPopup">
			<div class="pb-2 h-[86vh] md:h-[75vh] flex flex-col text-center">
				<p class="font-serif text-3xl">
					{{ numItemsCanReserve < 0 ? $t("seatsIoPopupTitleRemove") : $t("seatsIoPopupTitle") }}
				</p>

				<div
					class="flex flex-col justify-between grow max-md:landscape:overflow-y-auto sm:landscape:overflow-y-auto overflow-x-hidden">
					<div
						class="pt-4 italic text-sm"
						v-html="t('seatsIoPopupHelp')"></div>

					<div class="py-4">
						<p>{{ $t("seatsIoPopupList") }}:</p>
						<ul>
							<template v-for="[key, value] in items">
								<li v-if="!!value.reservationSection">
									<span>{{ value.title }} - {{ value.quantity }}x</span>
									<span
										v-if="deltaInSeatSelection[key] < 0"
										class="text-sm italic ml-2"
										>({{ $t("seatsIoPopupListRemove") }}: {{ Math.abs(deltaInSeatSelection[key]) }}x)</span
									>
								</li>
							</template>
						</ul>
					</div>

					<div class="text-right">
						<ButtonIconThenText
							class="text-ternary text-xs hoverEffects viewDetails"
							:text="showHelp ? $t('helpHide') : $t('helpShow')"
							icon="fa-solid fa-circle-info"
							@clicked="expandHelp" />
					</div>

					<transition name="slide-fade">
						<div
							v-if="showHelp"
							class="text-sm pt-2 text-left">
							<div
								v-html="
									$utilities.$isMobileDevice() ? t('seatsIoPopupMapHelpMobile') : t('seatsIoPopupMapHelpDesktop')
								"></div>
						</div>
					</transition>

					<div
						id="seatChart"
						class="grow"
						:class="{ shrink: showHelp }"></div>

					<div class="py-4 md:pb-0">
						<ButtonPrimary
							class="w-64 !rounded-lg"
							:disabled="!isSelectionValid"
							@click="confirmSelection">
							{{
								numItemsCanReserve > 0 ? $t("seatsIoPopupAutoReserve", { qty: numItemsCanReserve }) : $t("confirmation")
							}}
						</ButtonPrimary>
					</div>
				</div>
			</div>
		</ModalBase>
	</div>
</template>

<script setup>
	import * as Sentry from "@sentry/browser"
	import { SHOW_SEAT_SELECTOR_POPUP, HIDE_SEAT_SELECTOR_POPUP } from "assets/events/cart.js"
	import { useRefsStore } from "~/stores/RefsStore"
	import { useSettingsStore } from "~/stores/SettingsStore"
	import { useCartStore } from "~/stores/CartStore"
	import { storeToRefs } from "pinia"
	import { SHOW_ERROR } from "assets/events/errors.js"

	const { seatsIoWorkspaceKey, seatsIoEvent } = defineProps({
		seatsIoWorkspaceKey: String,
		seatsIoEvent: String,
	})

	const { t } = useI18n()
	const { $eventBus, $seatSelector, $messages, $utilities } = useNuxtApp()
	const refsStore = useRefsStore()
	const settingsStore = useSettingsStore()
	const cartStore = useCartStore()
	const { items } = storeToRefs(cartStore)
	const runtimeConfig = useRuntimeConfig()

	const seatSelectorPopup = ref(null)
	const maxSelectedObjects = ref([])
	const filteredCategories = ref([])
	const deltaInSeatSelection = ref({})
	const isSelectionValid = ref(false)
	const editingCategory = ref(null)
	const numItemsCanReserve = computed(() => {
		return Object.values(deltaInSeatSelection.value).reduce((acc, qty) => acc + qty, 0)
	})

	const showHelp = ref(false)
	const expandHelp = () => {
		showHelp.value = !showHelp.value
	}

	const setupSeatsChart = () => {
		//Seats.IO SDK is imported in the nuxt.config
		try {
			let seatsIoTokenConfig = {
				session: "continue",
			}
			if (cartStore.reservationHoldToken) {
				seatsIoTokenConfig.session = "manual"
				seatsIoTokenConfig.holdToken = cartStore.reservationHoldToken
			}

			let seatsObject = new seatsio.SeatingChart({
				...seatsIoTokenConfig,
				divId: "seatChart",
				workspaceKey: seatsIoWorkspaceKey,
				event: seatsIoEvent,
				loading: `<div class="w-32 h-32"><img src="${runtimeConfig.public.DEFAULT_LOADER}" alt="loading"/></div>`,
				fitTo: "widthAndHeight",
				colors: {
					colorSelected: "#a2a2a2",
				},
				showMinimap: !$utilities.$isMobileDevice(),
				showFullScreenButton: false, //Full screen button causes rendering issues when un-fullscreening
				tooltipInfo: (object) => $messages.$t("seatsIoLabel", { id: object.label }),
				showSeatLabels: true,
				objectTooltip: { showLabel: false },
				//Do not set the default maxSelectedObjects, otherwise seat selections get lost. Use the onChartRendered event handler instead.
				onObjectSelected: (seatObject) => {
					validateSelectionAndNotifyUser()
				},
				onObjectDeselected: (seatObject) => {
					validateSelectionAndNotifyUser()
				},
				onHoldFailed: () => {
					logErrorAndCloseModal("onHoldFailed")
				},
				onReleaseHoldFailed: () => {
					logErrorAndCloseModal("onReleaseHoldFailed")
				},
				onChartRenderingFailed: () => {
					logErrorAndCloseModal("onChartRenderingFailed")
				},
				onChartRendered: (chart) => {
					//Configure the chart to only allow a certain number of seats to be reserved
					chart
						.changeConfig({
							maxSelectedObjects: maxSelectedObjects.value,
							filteredCategories: filteredCategories.value,
							availableCategories: filteredCategories.value,
						})
						.catch((e) => {
							logErrorAndCloseModal("Error changing config", e)
						})
					zoomToEditingArea()
					pulseSeatsIfShouldBeRemoved()
					validateSelectionAndNotifyUser()
					cartStore.setReservationHoldToken(chart.holdToken)
				},
			})
			refsStore.setSeatsObjectRef(seatsObject)
			seatsObject.render()
		} catch (e) {
			logErrorAndCloseModal("Error loading chart", e)
		}
	}

	const validateSelectionAndNotifyUser = () => {
		const deltaInDesiredQuantity = $seatSelector.$getDeltaInDesiredSelection(refsStore.seatsObjectRef.selectedObjects)
		deltaInSeatSelection.value = deltaInDesiredQuantity

		const userShouldDeselect = Object.values(deltaInDesiredQuantity).some((qty) => qty < 0)
		isSelectionValid.value = !userShouldDeselect

		if (!userShouldDeselect) {
			//Highlight the selected seats if the user should deselect from a section
			refsStore.seatsObjectRef.unpulse(refsStore.seatsObjectRef.selectedObjects)
		}
	}

	const zoomToEditingArea = () => {
		// We're not using sections, always zoomToFilteredCategories
		refsStore.seatsObjectRef.zoomToFilteredCategories()
	}

	const pulseSeatsIfShouldBeRemoved = () => {
		const deltaInDesiredQuantity = $seatSelector.$getDeltaInDesiredSelection(refsStore.seatsObjectRef.selectedObjects)
		let seatsToPulse = []
		for (const productId in deltaInDesiredQuantity) {
			//Pulse the category selection if the user needs to remove a selection
			if (deltaInDesiredQuantity[productId] < 0) {
				const reservationSelectedSeats = items.value.get(productId).reservationSelectedSeats
				seatsToPulse = seatsToPulse.concat(reservationSelectedSeats)
			}
		}
		refsStore.seatsObjectRef.pulse(seatsToPulse)
	}

	const openModal = (options) => {
		maxSelectedObjects.value = options.maxSelectedObjects
		filteredCategories.value = Array.from(
			options.maxSelectedObjects.reduce((acc, obj) => acc.add(obj.category), new Set())
		)
		editingCategory.value = options.editingCategory

		refsStore.seatSelectorPopupRef.openModal()
		settingsStore.setIsSelectingSeat(true)

		if (refsStore.seatsObjectRef) {
			refsStore.seatsObjectRef.rerender()
		} else {
			setupSeatsChart()
		}
	}

	const resetCacheAndClose = () => {
		settingsStore.setIsSelectingSeat(false)
		refsStore.seatSelectorPopupRef.closeModal()
	}

	const confirmSelection = () => {
		try {
			const holdToken = refsStore.seatsObjectRef.holdToken
			const allHeldSeats = refsStore.seatsObjectRef.selectedObjects
			$seatSelector.$updateCartSeatSelection(holdToken, allHeldSeats)
		} catch (e) {
			logErrorAndCloseModal("Error getting chart data during confirmation", e)
		} finally {
			resetCacheAndClose()
		}
	}

	const closeModal = () => {
		resetCacheAndClose()
	}

	const logErrorAndCloseModal = (errMsg, exception) => {
		console.log(exception) //Log here so sentry can capture the message
		Sentry.captureMessage(`Seats IO Error - ${errMsg}: ${exception ? exception.message : "No Exception"}`, "error")
		resetCacheAndClose()

		$eventBus.$emit(SHOW_ERROR, {
			titleText: $messages.$t("seatsIoErrorPopupTitle"),
			bodyText: $messages.$t("seatsIoErrorPopupBody"),
		})
	}

	onMounted(() => {
		refsStore.setSeatSelectorPopupRef(seatSelectorPopup.value)

		//Do not fire these events directly, use the seatSelector Plugin
		$eventBus.$on(SHOW_SEAT_SELECTOR_POPUP, openModal)
		$eventBus.$on(HIDE_SEAT_SELECTOR_POPUP, closeModal)
	})

	onUnmounted(() => {
		$eventBus.$off(SHOW_SEAT_SELECTOR_POPUP)
		$eventBus.$off(HIDE_SEAT_SELECTOR_POPUP)
	})
</script>

<style lang="scss" scoped>
	#seatChart {
		@apply py-4;
		@apply h-[10em];
	}
</style>
